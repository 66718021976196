@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

#wwd-container {
  margin-top:5vh;
  margin-bottom:1vh;
  text-align: center;
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wwd-button {
  width:85%;
  height: 20%;
  background-color: #38a3a5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  margin-bottom:5%;
  background:none;
  box-shadow: none;
}

#wwd-text {
    color:black;
    font-size:28px;
    font-family: Lato;
    font-weight: 400;
}

#arrow-svg {
    position:absolute;
    left: 75%;
    height:36px;
}

#wwd-line {
  height: 2px;
  width: 65%;
  border-radius: 360px;
  background-color:black;
}