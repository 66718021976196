@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,500;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header {
  height: 30vh;
  width: 100%;
  position: relative;
}

#background-svg {
  position: absolute;
  z-index: -50;
  top: 0;
  height: 100%;
  width: 100%;
}

.header-pill {
  background: linear-gradient(100.78deg, #57cc99 0.07%, #80ed99 99.93%);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Ubuntu;
  font-weight: bold;
  background:none;
  box-shadow:none;
}
#shs-pill {
  width: 30%;
  height: 20%;
  top: 15%;
  left: 0%;
  border-radius: 60px;
}

#shs-pill-text {
  font-size: 8vw;
  font-weight: 300;
}

#csc-pill-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 45%;

}

#csc-pill {
  position: relative;
  width: 97%;
  height: 20%;
  border-radius: 60px;
  text-align: center;
}

#csc-pill-text {
  font-size: 8.7vw;
}

#remind-section{
    margin-top:5vh;
    width : 100%;
    height: 20vh;
    text-align: center;
}

#remind-circle {
  height: 43vw;
  width: 43vw;
  border-radius: 360px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3b73d4;
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.5);
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

#remind-text-container{
    margin-left: auto;
    margin-right:auto;
    height:25%;
    width: 50%;
    margin-top: 8%;
    background-color: #2a64c7;
    display:flex;
    align-items: center;
    justify-content:center;
    border-radius: 60px;
}

#remind-text {
    font-size:16px;
    color:white;
    font-family:Raleway;
    font-weight:500;
}

@-webkit-keyframes bounce {
    from {
        -webkit-transform: translateY(0) scale(1);
                transform: translateY(0) scale(1)
    }

    50% {
        -webkit-transform: translateY(5%) scale(1.05);
                transform: translateY(5%) scale(1.05)
    }

    to {
        -webkit-transform: translateY(0) scale(1);
                transform: translateY(0) scale(1)
    }
}

@keyframes bounce {
    from {
        -webkit-transform: translateY(0) scale(1);
                transform: translateY(0) scale(1)
    }

    50% {
        -webkit-transform: translateY(5%) scale(1.05);
                transform: translateY(5%) scale(1.05)
    }

    to {
        -webkit-transform: translateY(0) scale(1);
                transform: translateY(0) scale(1)
    }
}
#wwd-container {
  margin-top:5vh;
  margin-bottom:1vh;
  text-align: center;
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wwd-button {
  width:85%;
  height: 20%;
  background-color: #38a3a5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  margin-bottom:5%;
  background:none;
  box-shadow: none;
}

#wwd-text {
    color:black;
    font-size:28px;
    font-family: Lato;
    font-weight: 400;
}

#arrow-svg {
    position:absolute;
    left: 75%;
    height:36px;
}

#wwd-line {
  height: 2px;
  width: 65%;
  border-radius: 360px;
  background-color:black;
}
#carousel-container {
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70vh;
}
#cards-container{
    margin: 0;
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
}

.controller {
    height: 16vw;
    width: 16vw;
    background-color:#38A3A5;
    border-radius:360px;
    display:flex;
    justify-content:center;
    align-items: center;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.45);

}

.controller > img{
    margin:0;
    padding:0;
}

#controller1 > img{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
#controller-row{
    position:absolute;
    display:flex;
    flex-direction:row;
    width:97%;
    height:75%;
    align-items: center;
    justify-content: space-between;
}


.card {
    width: 80%;
    height: 90%;    
    border-radius: 20px;
    text-align: center; 
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 3s;
}

.card-header {
    margin-top:15%;
    color : white;
    font-family: Lato;
    font-weight: bold;
    font-size: 32px;
}

.card-desc {
    margin-top:15%;
color : white;
font-family: Lato;
font-weight: 300;
font-size:3vh;
width:75%;
}

.card-2 > .card-desc{
    font-size:2.7vh;
}
#footer {
    float:bottom;
    margin-bottom: 20vh;
}
#home-container {
    height:100%;
    width:100%;
}
#goMobileContainer{
    height: 100vh;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align:center;
}

#goMobileText{
    font-family: Roboto;
    font-weight: 300;
    font-size:64px;
}
