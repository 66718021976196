@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,500;0,700;1,400&display=swap");

#header {
  height: 30vh;
  width: 100%;
  position: relative;
}

#background-svg {
  position: absolute;
  z-index: -50;
  top: 0;
  height: 100%;
  width: 100%;
}

.header-pill {
  background: linear-gradient(100.78deg, #57cc99 0.07%, #80ed99 99.93%);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Ubuntu;
  font-weight: bold;
  background:none;
  box-shadow:none;
}
#shs-pill {
  width: 30%;
  height: 20%;
  top: 15%;
  left: 0%;
  border-radius: 60px;
}

#shs-pill-text {
  font-size: 8vw;
  font-weight: 300;
}

#csc-pill-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 45%;

}

#csc-pill {
  position: relative;
  width: 97%;
  height: 20%;
  border-radius: 60px;
  text-align: center;
}

#csc-pill-text {
  font-size: 8.7vw;
}
