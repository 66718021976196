#carousel-container {
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70vh;
}
#cards-container{
    margin: 0;
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
}

.controller {
    height: 16vw;
    width: 16vw;
    background-color:#38A3A5;
    border-radius:360px;
    display:flex;
    justify-content:center;
    align-items: center;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.45);

}

.controller > img{
    margin:0;
    padding:0;
}

#controller1 > img{
    transform: rotate(180deg);
}
#controller-row{
    position:absolute;
    display:flex;
    flex-direction:row;
    width:97%;
    height:75%;
    align-items: center;
    justify-content: space-between;
}

