@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

#remind-section{
    margin-top:5vh;
    width : 100%;
    height: 20vh;
    text-align: center;
}

#remind-circle {
  height: 43vw;
  width: 43vw;
  border-radius: 360px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3b73d4;
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.5);
  animation-duration: 1.5s;
  animation-name: bounce;
  animation-iteration-count: infinite;
}

#remind-text-container{
    margin-left: auto;
    margin-right:auto;
    height:25%;
    width: 50%;
    margin-top: 8%;
    background-color: #2a64c7;
    display:flex;
    align-items: center;
    justify-content:center;
    border-radius: 60px;
}

#remind-text {
    font-size:16px;
    color:white;
    font-family:Raleway;
    font-weight:500;
}

@keyframes bounce {
    from {
        transform: translateY(0) scale(1)
    }

    50% {
        transform: translateY(5%) scale(1.05)
    }

    to {
        transform: translateY(0) scale(1)
    }
}