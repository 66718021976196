@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');


.card {
    width: 80%;
    height: 90%;    
    border-radius: 20px;
    text-align: center; 
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 3s;
}

.card-header {
    margin-top:15%;
    color : white;
    font-family: Lato;
    font-weight: bold;
    font-size: 32px;
}

.card-desc {
    margin-top:15%;
color : white;
font-family: Lato;
font-weight: 300;
font-size:3vh;
width:75%;
}

.card-2 > .card-desc{
    font-size:2.7vh;
}